import { getFromBreakpoint } from '@vakantiesnl/components/src/styles/breakpoints';
import { makeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

export const useStyles = makeStyles<void, 'label' | 'content' | 'imageContainer'>()((theme, _, classes) => ({
	card: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		textDecoration: 'none',
		gap: theme.spacing.xxsmall,

		'&:hover': {
			color: theme.link.color.light.default,
		},
	},
	colorCard: {
		height: '100px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		boxSizing: 'border-box',
		padding: theme.spacing.small,
		textAlign: 'center',
		borderRadius: theme.borderRadius.rounded,

		[`& .${classes.content}`]: {
			alignItems: 'center',
		},
		[`& .${classes.label}`]: {
			color: 'inherit',
		},
	},
	verticalCard: {
		[`& .${classes.imageContainer}`]: {
			height: '396px',

			[getFromBreakpoint('md')]: {
				height: '480px',
			},
		},

		[`& .${classes.content}`]: {
			position: 'absolute',
			left: theme.spacing.small,
			right: theme.spacing.large,
			top: theme.spacing.small,
		},
		[`& .${classes.label}`]: {
			font: theme.typography.headingLg,
			color: theme.palette.neutral[0],
			wordBreak: 'break-word',
			textShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',

			[getFromBreakpoint('md')]: {
				font: theme.typography.heading2Xl,
			},
		},
	},
	image: {
		width: '100%',
		height: 'auto',
		objectFit: 'cover',
	},
	imageContainer: {
		display: 'flex',
		position: 'relative',
		width: '100%',
		overflow: 'hidden',
		borderRadius: theme.borderRadius.rounded,
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing.small,
	},
	label: {
		overflow: 'hidden',
		display: '-webkit-box',
		WebkitLineClamp: 2,
		WebkitBoxOrient: 'vertical',
	},
	green: {
		color: theme.palette.teal[160],
		backgroundColor: theme.palette.teal[20],
		borderColor: theme.palette.teal[20],

		'&:hover': {
			color: theme.palette.teal[160],
		},
	},
	yellow: {
		color: theme.palette.yellow[160],
		backgroundColor: theme.palette.yellow[20],
		borderColor: theme.palette.yellow[20],

		'&:hover': {
			color: theme.palette.yellow[160],
			[`& .${classes.label}`]: {
				color: theme.palette.yellow[160],
			},
		},
	},
	blue: {
		color: theme.palette.semantic.blue[160],
		backgroundColor: theme.palette.semantic.blue[20],
		borderColor: theme.palette.semantic.blue[20],

		'&:hover': {
			color: theme.palette.semantic.blue[160],
			[`& .${classes.label}`]: {
				color: theme.palette.semantic.blue[160],
			},
		},
	},
	purple: {
		color: theme.palette.purple[160],
		backgroundColor: theme.palette.purple[20],
		borderColor: theme.palette.purple[20],

		'&:hover': {
			color: theme.palette.purple[160],
			[`& .${classes.label}`]: {
				color: theme.palette.purple[160],
			},
		},
	},
	orange: {
		color: theme.palette.orange[160],
		backgroundColor: theme.palette.orange[20],
		borderColor: theme.palette.orange[20],
		'&:hover': {
			color: theme.palette.orange[160],
			[`& .${classes.label}`]: {
				color: theme.palette.orange[160],
			},
		},
	},
	cheapestPriceBlock: {
		zIndex: theme.zIndex.floating,
		position: 'absolute',
		top: theme.spacing.xxsmall,
		right: theme.spacing.xxsmall,
	},
}));
